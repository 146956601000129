.blurEffect {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 48px;
  &:before {
    border-radius: 21px;
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.15);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(1.22px);
  }
}
