@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Regular.ttf');
}

.plyr--video {
  min-height: 220px;
  max-height: 420px;
  video {
    object-fit: cover;
  }
  .plyr__progress {
    input {
      cursor: pointer;
    }
  }
  .plyr__controls {
    bottom: 40px;
    left: 40px;
    right: 40px;
    border-radius: 21px;
    padding: 10px;
    &:before {
      border-radius: 21px;
      content: '';
      position: absolute;
      background: rgba(255, 255, 255, 0.15);
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(1.22px);
    }
    --plyr-color-main: #ffffff;
    --plyr-video-controls-background: transparent;
    --plyr-tab-focus-color: transparent;
    --plyr-video-control-color-hover: #000000;
    --plyr-control-icon-size: 12px;
    --plyr-control-radius: 14px;
    --plyr-range-thumb-height: 10px;
    .plyr__volume {
      max-width: 45px;
      min-width: 26px;
      width: 5%;
      margin: 0;
      input {
        opacity: 0;
        cursor: pointer;
        pointer-events: none;
        transform: rotate(269deg);
        position: absolute;
        top: -30px;
        left: -20px;
        width: 55px;
        transition: all 0.3s;
        padding: 0 0 0 10px;
      }
      &:hover {
        input {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}
